import React from 'react'

import Home from '@components/Home'
import { initSocket } from '@contexts/Socket'

const IndexPage = () => {
  React.useEffect(() => {
    initSocket()
  }, [])

  return <Home />
}

export default IndexPage
